import React from "react";
import { Box, Heading, Stack, Container, Flex } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Slider from "react-slick";

const ReactSlider = styled(Slider)`
  width: 100%;
`;

const sliderSetting = {
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 6,
  slidesToScroll: 2,
  speed: 500,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
  ],
};

const Slide = ({ imageData, name }) => {
  return (
    <Flex mx={"auto"} justify={"center"} align={"center"}>
      <GatsbyImage image={getImage(imageData)} alt={name} />
    </Flex>
  );
};

const OurClients = (props) => {
  const queryResult = useStaticQuery(graphql`
    query ClientImages {
      allFile(
        filter: { relativeDirectory: { eq: "clients" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 75)
            }
          }
        }
      }
    }
  `);

  const ClientImages = queryResult.allFile.edges;
  return (
    <Box>
      <Container
        maxW={"7xl"}
        py={10}
        marginTop={"1rem"}
        as={Stack}
        spacing={12}
        align="center"
      >
        <Box spacing={0} align={"center"}>
          <Heading
            color="#3182ce"
            _after={{
              content: '""',
              display: "block",
              marginTop: "0.25rem",
              width: "5rem",
              height: "0.25rem",
              backgroundColor: "#2b2f69",
            }}
          >
            Our Clients
          </Heading>
        </Box>
        <ReactSlider {...sliderSetting}>
          {ClientImages.map((image, i) => (
            <Slide imageData={image.node} key={i} name={image.name} />
          ))}
        </ReactSlider>
      </Container>
    </Box>
  );
};

export default OurClients;
