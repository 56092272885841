import React from "react";
import { Box, Heading, Stack, Container, Text } from "@chakra-ui/react";
import Slider from "react-slick";

const Slide = ({ content, name, company }) => {
  return (
    <Box
      bg={"blue.50"}
      py={16}
      px={8}
      shadow={"md"}
      spacing={{ base: 8, md: 10 }}
      align={"center"}
      direction={"column"}
    >
      <Text
        fontSize={{ base: "xl", md: "2xl" }}
        textAlign={"center"}
        maxW={"4xl"}
      >
        {content}
      </Text>
      <Box textAlign={"center"} mt={5}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={"blue.700"}>
          {company}
        </Text>
      </Box>
    </Box>
  );
};

const slideContent = [
  {
    content:
      "As we have been dealing with BSCIC for the past 5 years, we would like to mention the assistance of BSCIC staff. They report everything on time without any delay. We are extremely glad with your services.",
    name: "Ramal",
    company: "GMP quality consultancy",
  },

  {
    content:
      "It was good experience for me with BSCIC. Responding the team very well and quickly. His in-depth industry experience and hold on subject matter made our learning a pleasant experience.",
    name: "Yasir",
    company: "Splendid Quality Consultants",
  },

  {
    content:
      "BSCIC had a good team who are committed professionally and ethicality towards compliance requirements and open to share the experiences for continual improvement of system. Happy to work with them for our clients requirements.",
    name: "Lenin G",
    company: "AQS Management Consultancy",
  },
  {
    content:
      "The course was presented in an enthusiastic way. Tutor were very supportive in the interactive sessions by sharing their experiences related to their industries. I am sure that this training will help me in my future endeavours. Thank you for a great course.",
    name: "Muhammad Shahanavas Poduvachola",
    company: "LA Student",
  },
  {
    content:
      "Personally , ISO 22000 – FSMS LA certification will certainly help me in my current role and also would open up the doors for future opportunities.Course content and presentation was very well designed and managed with in the set timelines. BSCIC's tutor is very knowledgeable professional in the field.",
    name: "Chandra K",
    company: "Trading Safely & Legally",
  },
  {
    content:
      "I would like to thank you for the training you conducted as thorough and well organized. The discussions were truly inspiring. I particularly enjoyed the visual illustrations, which made the content understandable. I look forward to attending future training sessions.",
    name: "Radhakrishnan",
    company: "LA Student",
  },
];

const sliderSetting = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Testimonials() {
  return (
    <Box>
      <Container
        maxW={"7xl"}
        py={10}
        marginTop={"1rem"}
        as={Stack}
        spacing={12}
      >
        <Box spacing={0} align={"center"}>
          <Heading
            color="#3182ce"
            _after={{
              content: '""',
              display: "block",
              marginTop: "0.25rem",
              width: "5rem",
              height: "0.25rem",
              backgroundColor: "#2b2f69",
            }}
          >
            Testimonials
          </Heading>
        </Box>
        <Slider {...sliderSetting}>
          {slideContent.map((content, index) => (
            <Slide {...content} key={index} />
          ))}
        </Slider>
      </Container>
    </Box>
  );
}
